<template>
    <div>
        <div class="bg"></div>
        <div class="coupon_wrap">
            <div class="coupon">
                <h2>验证手机号码激活观看</h2>
                <div class="coupon_content">
                    <div>
                        <input type="text" v-model="mobile" placeholder="请输入手机号码" />
                    </div>
                    <div class="smsCode">
                        <input type="text" v-model="code" placeholder="请输入验证码" />
                        <div class="fr smsCode_code_b" :class="countDown ? 'smsCode_code_active' : ''"
                            @click="getCode()">
                            {{ countDown ? Endtime : "获取验证码" }}
                        </div>
                    </div>
                    <el-button type="primary" @click="bindFun">激活</el-button>
                </div>
                <div class="close" @click="closeFun">
                    <img src="../assets/images/close.svg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "binding",
    props: ["type"],
    data() {
        return {
            mobile: '',
            code: "",

            countDown: false,
            Endtime: 60,
        };
    },
    mounted() { },
    methods: {
        closeFun() {
            this.$parent.popStatus = false;
        },

        // 获取验证码
        getCode() {
            var that = this;
            var params = {
                mobile: this.mobile,
            };
            this.$api.POST(this.$face.loginSms, params, function (res) {
                that.countdownFun();
                that.countDown = true;
            });
        },
        // 验证码倒计时
        countdownFun() {
            setTimeout(() => {
                this.Endtime--;
                if (this.Endtime == 0) {
                    this.countDown = false;
                    this.Endtime = 60;
                } else {
                    this.countdownFun();
                }
            }, 1000);
        },
        bindFun() {
            this.$api.POST(
                this.$face.member_activate_mobile,
                {
                    mobile: this.mobile,
                    code: this.code,
                },
                (res) => {
                    if (res.code == 200) {
                        this.$parent.popStatus = false;
                        this.$utile.prompt("success", "激活成功");
                        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
                        userInfo.is_guide = 1;
                        this.$parent.$parent.getUser();
                        localStorage.setItem("userInfo", JSON.stringify(userInfo));
                    } else {
                        let scrollTop = document.documentElement.clientHeight / 2 - 30;
                        //this.$utile.prompt("error", res.msg+'11111');
                        //console.log(scrollTop);
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'error',
                            offset: scrollTop
                        });
                    }
                }
            );
        },
    },
};
</script>

<style scoped>
.bg {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
    z-index: 1009;
}

.coupon_wrap {
    width: 545px;
    /* height: 288px; */
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1010;
}

.coupon {
    position: relative;
}

.coupon h2 {
    height: 51px;
    line-height: 51px;
    font-size: 16px;
    font-weight: bold;
    color: #5957ff;
    text-align: center;
    border-bottom: 1px #e4e5e5 solid;
}

.close {
    position: absolute;
    top: 17px;
    right: 22px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    opacity: 0.6;
}

.close img {
    width: 16px;
    height: 16px;
}

.coupon_content {
    padding: 30px 0;
    text-align: center;
}

.coupon_content > div {
    width: 260px;
    height: 42px;
    display: block;
    margin: 0 auto 10px;
    border: 1px #e4e5e5 solid;
    border-radius: 8px;
    padding: 0 15px;
    box-sizing: border-box;
    transition: 0.3s;
    text-align: center;
}
.coupon_content input{
    width: 100%;
    line-height: 40px;
}

.coupon_content .smsCode{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4px;
}


.coupon_content>>>.el-button--primary {
    width: 119px;
    height: 36px;
    background-color: #5857ff;
    line-height: 1;
    margin-top: 20px;
}

.close {
    position: absolute;
    top: 17px;
    right: 22px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    opacity: 0.6;
}

.close img {
    width: 16px;
    height: 16px;
}


.smsCode_code_b {
  width: 115px;
  height: 36px;
  line-height: 36px;
  background-color: #5857ff;
  border-radius: 4px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  border: solid 1px #5857ff;
  box-sizing: border-box;
}

.smsCode_code_active {
  background-color: #f5f5f5;
  border: solid 1px #f0f1f1;
  color: #acadad;
}
</style>